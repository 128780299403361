
import style from "./Pagination.module.scss";
import {useSearchParams} from "react-router-dom";



function Pagination({pageCount}) {
    let [queryParams, setQueryParams] = useSearchParams();
    let page = queryParams.get("page") || 1;

    function changePage(page){
        queryParams.set("page", page)
        setQueryParams(queryParams)
    }

    let pages = [page];
    //takes the numeric value from the page
    let verifiedPage = parseInt(page)
    // TOdo fix superior limit and maybe always keep n pages
    for (let i = 1; i <= 5; i++) {
        if (verifiedPage + i <= pageCount) {
            pages.push(verifiedPage + i);
        }
        if (verifiedPage - i >= 1){
            pages.unshift(verifiedPage - i);
        }
    }

    return (
        <div className={style.pagination}>
        {pages.map(
            i => (
                <button className={i===page ? style.active: null}
                    onClick={() => {changePage(i)}}
                    key={i}
                >
                    {i}
                </button>
            )
            )}
        </div>
    )
}

export default Pagination;