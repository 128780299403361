import { Link }from 'react-router-dom';
import style from "./MarketPlaceGamesDisplay.module.scss";

function MarkePlaceGamesDisplay({store, games}) {
    return(
            <div className="menu_categories  bg_white py-20 px-15 w-100">

                <Link className="color_brand" to={store ? "/" : "/stash/"}>
                    <span>
                    <img className={style.logo_bar}
                        src="/img/Font_text/todo.png" alt="Todo"/>
                    </span>
                </Link>

            {games.map((game, i) => (
                <Link to={store? `/${game.slug}/` : `/stash/${game.slug}/`} key={i}>
                    <span>
                    <img className={style.logo_bar}
                        src={game.logo} alt={game.name}/>
                    </span>
                </Link>
                ))}
            </div>
            )
}

export default MarkePlaceGamesDisplay;