import style from "./ProductTypeFilters.module.scss";
import React from "react";
import {useSearchParams} from "react-router-dom";


function ProductTypeFilters(){
    let [queryParams, setQueryParams] = useSearchParams();

    let productType = queryParams.get("type");

    const setProductType = (type) => {
        if (type === null){
            queryParams.delete("type")
        } else {
            queryParams.set("type", type)
        }
      queryParams.delete("page")
      setQueryParams(queryParams)
    }


    return(
        <div>
            <div className="d-flex align-items-center">
              <div>
                <div className="row justify-content-between align-items-center section__head">
                  <div className="col-lg-auto">
                    <div className="menu_categories menu_start">
                      <div className="switch_item">
                        <span className={`btn btn-sm ${productType === null ? style.active : "btn-white"}`}
                              onClick={() => {setProductType(null)}}>
                          Todos los productos
                        </span>
                      </div>
                      <div className="switch_item">
                        <span className={`btn btn-sm ${productType === "SEA" ? style.active : "btn-white"}`}
                              onClick={() => {setProductType("SEA")}}>
                          Producto Cerrado
                        </span>
                      </div>
                      <div className="switch_item">
                        <span className={`btn btn-sm ${productType === "SIN" ? style.active : "btn-white"}`}
                              onClick={() => {setProductType("SIN")}}>
                          Cartas Sueltas
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}

export default ProductTypeFilters;