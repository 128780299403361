import React, {useEffect, useState} from 'react';
import useDocumentTitle from '../template/components/useDocumentTitle';
import WideButton from "../components/buttons/WideButton";
import {getReview, saveReview} from "../actions/reviews"
import {useParams} from "react-router-dom";
import Rating from "react-rating";
import {toast, ToastContainer} from "react-toastify";

const FeedbackForm = () => {
  useDocumentTitle('Feedback');
  const [seller, setSeller] = useState(null)
  const [sellerScore, setSellerScore] = useState(null)
  const [platformScore, setPlatformScore] = useState(null)
  const [sellerComment, setSellerComment] = useState("")
  const [platformComment, setPlatformComment] = useState("")

  let { sellId } = useParams();

  useEffect(() => getReview(sellId, (data) => {
    setSeller(data.seller)
    setSellerComment(data.sell.comment)
    setSellerScore(data.sell.score)
    setPlatformComment(data.platform.comment)
    setPlatformScore(data.platform.score)
  }), [])

  const saveForm = () => {
    if(platformScore === null || sellerScore === null){
      return toast.error("Por favor, califica la plataforma y el vendedor antes de enviar.")
    }
    saveReview(
        sellId,
        {
          platform: {
            score: platformScore,
            comment: platformComment,
          },
          seller: {
            score: sellerScore,
            comment: sellerComment,
          }
        },
        () => {
          toast.success("¡Gracias por tus comentarios.!")
        }
    )
  }

  return (
    <div >
      <div className="hero__1 h-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="hero__left space-y-20">
              <h1 className="text-center">
                Cuéntanos como fue tu experiencia comprando con
                <span>{seller?.store ?
                    <img style={{maxWidth: 80}} src={seller?.store?.logo}/> : null}</span>
                <span>{seller?.store ? seller?.store?.name : seller?.name}</span>
              </h1>
              <p className="text-center">
                ¿Cómo calificarías la atención de tu vendedor
                (<span>{ seller?.store ? seller?.store?.name : seller?.name}</span>)?
              </p>
              <p className="text-center mt-0" style={{fontSize: 30}}>
                <Rating
                    initialRating={sellerScore}
                    onChange={setSellerScore}
                    stop={10}
                    emptySymbol="ri-star-line"
                    fullSymbol="ri-star-fill"
                />
              </p>
              <p className="text-center">
                ¿Qué tan probable es que recomiendes nuestra plataforma?
              </p>
              <p className="text-center mt-0" style={{fontSize: 30}}>
                <Rating
                    initialRating={platformScore}
                    onChange={setPlatformScore}
                    stop={10}
                    emptySymbol="ri-star-line"
                    fullSymbol="ri-star-fill"
                />
              </p>
              <p className="text-center">
                Puedes agregar un comentario sobre tu vendedor, para que más compradores sepan que esperar:
              </p>
              <textarea rows="3" value={sellerComment} onChange={(event) => {
                setSellerComment(event.target.value)}}/>
              <p className="text-center">
                Queremos seguir mejorando la plataforma, ayúdanos comentándonos que te pareció la experiencia y si
                hay cosas que te gustaría que mejoremos:
              </p>
              <textarea rows="3" value={platformComment} onChange={(event) => {
                setPlatformComment(event.target.value)}}/>
              <WideButton text={"Enviar"} active={true} onClick={saveForm}/>
            </div>
          </div>
        </div>
      </div>
      </div>
    <ToastContainer position={"bottom-right"}/>
    </div>
  );
};

export default FeedbackForm;