import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AuthContext} from "../actions/auth";

function MobileMenu() {
  const {user} = useContext(AuthContext);

    const clearAuth = () => {
      localStorage.setItem("access", null)
      localStorage.setItem("refresh", null)
      window.location = "/"
  }

  return (
    <div>
      <div className="header__mobile__menu space-y-40">
        { user ? (
            <span>Bienvenido {user.name}</span>
        ) : null}
        <ul className="d-flex space-y-20">
            <li>
              <Link to={"/stash"} className="color_black">
                Mercado
              </Link>
          </li>
            <li>
              <Link to={"/carritos"} className="color_black">
                Mi Carrito
              </Link>
          </li>

          {user ? (

              <li>
                <span onClick={clearAuth}>
                  <i className={`ri-close-circle-line`}/>
                  Cerrar sesión
                </span>
              </li>

              ) : (
                  <Link className="btn btn-sm bg_brand" to="/login">
                    Ingresar / Registrarme
                  </Link>
              )}



        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;
