import {post} from "./backend";


export function getPaymentOptions(sellerId, cartId, setDeliveryOptions){
    post(
        `payments/${sellerId}/options/`,
        {cart: cartId},
        true,
    ).then(response => {
        setDeliveryOptions(response.data)
    })
}

export function savePaymentDetails(cartUuid, paymentMethod, handleResponse){
    post(
        `cart/${cartUuid}/payment/`,
        {payment_method: paymentMethod},
        true,
    ).then(response => {
        handleResponse(response.data)
    })
}


export function getPaymentUrl(sellerId, cartId, storeId, handleResponse){
    post(
        `payments/${sellerId}/mercado_pago/`,
        {cart: cartId, store: storeId},
        true,
    ).then(response => handleResponse(response.data))
}
