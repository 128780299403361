// import useDocumentTitle from '../../../components/useDocumentTitle';
import React, {useContext} from 'react';
import Header from '../../views/store/Header';
import Footer from '../../views/store/Footer';
import {Link} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import style from "../../components/buttons/WideButton.module.scss";
import useDocumentTitle from '../../template/components/useDocumentTitle';
import FloatingButton from '../../components/buttons/FloatingButton';
import { StoreContext } from '../../actions/stores';

const ThankyouPurchase = () => {
  useDocumentTitle('Gracias');
  const store = useContext(StoreContext)
  return (
    <div>
      <Header />
      <div className="container">
        <div className="hero_newsletter box bg_white">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-5">
              <img
                className="img-fluid hero__img"
                id="store_image"
                src={store?.main_logo}
                alt="logo"
              />
            </div>
            <div className="col-lg-6 left__side">
              <div className="content space-y-20">
                <h1 className="text-center">
                  ¡Gracias por tu compra!
                </h1>
                <p className="text-center">
                {/*<p className="hero__desc">*/}
                  Si tienes cualquier duda o requieres hacer una aclaración
                  <br/>
                  con tu pedido escríbenos al {store?.phone}.
                  <br/>
                  Puedes ver el detalle de tu compra
                  <br/>
                  en el enlace siguiente:
                  <br/>
                  <br/>
                  <Link to="/carritos" className={style.button}>
                    Mis Carritos
                  </Link>
                </p>

                <div className="mr-auto">
                  <ToastContainer position="bottom-right" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
      <FloatingButton/>
    </div>
  );
};

export default ThankyouPurchase;
