import {get} from "./backend"


export function getProducts(game_slug, queryParams, sellerId, handleResponse){
    let params = {
        page:queryParams.get("page"),
        state:queryParams.get("state"),
        game: game_slug,
        search: queryParams.get("search"),
        type_of: queryParams.get("type"),
        expansion: queryParams.get("expansion"),
        rarity: queryParams.get("rarity"),
        order_by: "market",
        seller: sellerId || queryParams.get("seller"),
    }
    if (sellerId){
        params.is_available_on_market = true;
    }
    get(
        "products/market/",
        params,
    ).then(response => {handleResponse(response.data)}
    )
}


export function getCartByAvailability(setCartAvailabilities){
    get(
        "cart/quantity_by_availability",
        {},
        true,
    ).then(response => {
            setCartAvailabilities(response.data);
        }
    ).catch((error) => {setCartAvailabilities({})})
}
