import Collapsible from 'react-collapsible';
import Select from "react-select";
import {getStates} from "../../actions/catalogues"
import {getExpansions, getRarities} from "../../actions/games"
import {useContext, useEffect, useRef, useState} from "react";
import WideButton2 from "../buttons/WideButton2";
import {useSearchParams} from "react-router-dom";
import {StoreContext} from "../../actions/stores";


function AdvancedFiltersPopUp({game_slug}){
    const store = useContext(StoreContext)
    let [queryParams, setQueryParams] = useSearchParams();
    const [stateList, setStateList] = useState([])
    const [expansionList, setExpansionList] = useState([])
    const [rarityList, setRarityList] = useState([])

    const [filters, setFilters] = useState({
        state: queryParams.get("state"),
        expansion: queryParams.get("expansion"),
        rarity: queryParams.get("rarity"),
    })

    useEffect(() => {
        if(game_slug){
            getExpansions(game_slug, setExpansionList)
            getRarities(game_slug, setRarityList)
        }
    }, [game_slug])

    return (
        <Collapsible
            className="custom"
            trigger={
                <button className="btn btn-white mb-3">
                    Búsqueda Avanzada
                </button>
            }
            onOpen={() => {
                getStates(setStateList)
            }}
        >
            <div className="mb-3" style={{zIndex: 10}}>
            { store ? null : (
                <div>
                    <p>Estado de la República Mexicana</p>
                    <Select
                        value={
                            stateList.length > 0 ? stateList.find(
                                (e) => e.code === filters.state
                            ) : null
                        }
                        placeholder={"Estado de la República Mexicana"}
                        defaultValue={filters?.state}
                        isClearable={true}
                        options={stateList}
                        getOptionValue={(option) => option.code}
                        getOptionLabel={(option) => option.name}
                        onChange={(value) => {
                            setFilters({...filters, state: value?.code})
                        }}
                        menuPosition={"fixed"}
                    />
                </div>
            )}

            <p>Expansión</p>
            <Select
                value={expansionList.length > 0 ? expansionList.find((e) => e.slug === filters.expansion) : null}
                placeholder={"Expansión"}
                defaultValue={filters?.expansion}
                isClearable={true}
                isDisabled={game_slug === undefined}
                options={expansionList}
                getOptionValue={(option) => option.slug}
                getOptionLabel={(option) => option.name}
                onChange={(value) => {
                    setFilters({...filters, expansion: value?.slug})
                }}
                menuPosition={"fixed"}
            />

            <p>Rareza</p>
            <Select
                value={rarityList.length > 0 ? rarityList.find((e) => e.id === filters.rarity) : null}
                placeholder={"Rareza"}
                defaultValue={filters?.rarity}
                isClearable={true}
                isDisabled={game_slug === undefined}
                options={rarityList}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                onChange={(value) => {
                    setFilters({...filters, rarity: value?.id})
                }}
                menuPosition={"fixed"}
            />


            <WideButton2 text={"Buscar"} onClick={() => {
                if(filters.state){queryParams.set("state", filters.state)}
                else{queryParams.delete("state")}
                if(filters.expansion){queryParams.set("expansion", filters.expansion)}
                else{queryParams.delete("expansion")}
                if(filters.rarity){queryParams.set("rarity", filters.rarity)}
                else{queryParams.delete("rarity")}
                queryParams.delete("page")
                setQueryParams(queryParams)
            }}/>
            </div>
        </Collapsible>
    )
}


export default AdvancedFiltersPopUp;