import {get, put} from "./backend"


export function getReview(sellId, handleResponse){
    get(
        `reviews/sell/${ sellId }/`,
        {},
        true,
    ).then(response => {
          handleResponse(response.data)
        }
    ).catch((error) => {
        if(error.response.status === 403){
            window.location = `/login?after=${window.location.pathname}`
        }
    })
}
export function saveReview(sellId, data, handleResponse){
    put(
        `reviews/sell/${ sellId }/`,
        data,
        true,
    ).then(response => {
          handleResponse(response.data)
        }
    ).catch((error) => {
        if(error.response.status === 403){
            window.location = `/login?after=${window.location.pathname}`
        }
    })
}
